import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "../contact.css";

const FollowUs = () => {
  const [linkFollowUs, setLinkFollowUs] = useState("");
  const [langENFollowUs, setLangENFollowUs] = useState("");
  const [isDisabledAddLinkFollowUs, setIsDisabledAddLinkFollowUs] =
    useState(false);
  const [dataFollowUs, setDataFollowUs] = useState([]);
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;

  const fetchDataFollowUs = async () => {
    try {
      const response = await fetch(`${LinkLikeBachend}read/view_FollowUs.php`);
      const dataFollowUsAraay = await response.json();
      setDataFollowUs(dataFollowUsAraay);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  useEffect(() => {
    fetchDataFollowUs();
  }, []);

  const handleSubmitFollowUs = async (e) => {
    e.preventDefault();

    setIsDisabledAddLinkFollowUs(true);

    const formDataFollowUs = new FormData();
    formDataFollowUs.append("link_view_follow", linkFollowUs);
    formDataFollowUs.append("name_view_follow", langENFollowUs);

    const response = await fetch(`${LinkLikeBachend}create/add_FollowUs.php`, {
      method: "POST",
      body: formDataFollowUs,
    });

    const resultText = await response.text();

    if (response.ok) {
      if (resultText == "successfully") {
        toast.success("The statement has been added successfully");

        setLinkFollowUs("");

        setLangENFollowUs("");

        setIsDisabledAddLinkFollowUs(false);

        fetchDataFollowUs();
      } else {
        toast.error("Error when adding statement");
        setIsDisabledAddLinkFollowUs(false);
      }
    } else {
      const errorText = await response.text();
      console.log("حدث خطأ:", errorText);
      setIsDisabledAddLinkFollowUs(false);
    }
  };

  const handleRemovedataFollowUs = async (idFollowUs) => {
    const formDataRemoveFollowUs = new FormData();
    formDataRemoveFollowUs.append("idFollowUs", idFollowUs);

    const response = await fetch(
      `${LinkLikeBachend}delete/delete_FollowUs.php`,
      {
        method: "POST",
        body: formDataRemoveFollowUs,
      }
    );

    const result = await response.text();

    if (response.ok) {
      if (result == "successfully") {
        toast.success("The data has been deleted successfully");

        setDataFollowUs(
          dataFollowUs.filter((itemdata) => itemdata.id !== idFollowUs)
        );
      } else {
        toast.error("Error when deleting data");
        console.log(result);
      }
    } else {
      const errorText = await response.text();
      console.log("حدث خطأ:", errorText);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmitFollowUs} className="FollowUsForm">
        <input
          type="url"
          value={linkFollowUs}
          onChange={(e) => setLinkFollowUs(e.target.value)}
          placeholder="Social media link"
          required
        />

        <input
          type="text"
          value={langENFollowUs}
          onChange={(e) => setLangENFollowUs(e.target.value)}
          placeholder="Social networking site name"
          required
        />

        <button type="submit" disabled={isDisabledAddLinkFollowUs}>
          Submit
        </button>
      </form>

      {dataFollowUs.length > 0 ? (
        <div className="FollowUsFormDiv">
          {dataFollowUs.map((itemdata, index) => (
            <div key={index}>
              <button
                className="removedataFollowUs"
                onClick={() => handleRemovedataFollowUs(itemdata.id)}
              >
                ×
              </button>

              <div className="FollowUsFormUpdate">
                <a href={itemdata.link} target="_blank">
                  {itemdata.link}
                </a>
                <p>{itemdata.name_view_follow}</p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p style={{ textAlign: "center" }}>No images available.</p>
      )}
    </div>
  );
};

export default FollowUs;
