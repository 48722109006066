import React from "react";
import Footer from "../footer/Footer";

const ShowFooter = ({ children, setDirection }) => {
  return (
    <>
      {children}
      <Footer setDirection={setDirection} />
    </>
  );
};

export default ShowFooter;
