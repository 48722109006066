import React, { useState } from "react";
import "./siteManager.css";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const SiteManager = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const navigate = useNavigate();
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;

  const authSuccess = localStorage.getItem("loginSuccess");

  if (authSuccess === "true") {
    return <Navigate to="/dashboard" />;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    setButtonDisabled(true);

    const response = await fetch(`${LinkLikeBachend}login.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    });

    setEmail("");
    setPassword("");

    if (response.ok) {
      const data = await response.json();
      if (data.message === "success") {
        toast.success("You have been logged in successfully");
        localStorage.setItem("loginSuccess", true);
        setTimeout(() => {
          navigate("/dashboard", { replace: true });
        }, 2000);
      } else {
        toast.error("Error in login data");
        setButtonDisabled(false);
        console.log(data);
      }
    } else {
      const errorText = await response.text();
      console.log("حدث خطأ:", errorText);
      setButtonDisabled(false);
    }
  };

  return (
    <div className="SiteManager_login">
      <div className="container">
        <h1 className="title">Login Dashboard</h1>
        <div className="card">
          <form method="POST" onSubmit={handleSubmit}>
            <input
              type="email"
              placeholder="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />

            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />

            <div className="buttons">
              <button
                type="submit"
                className="login-button"
                disabled={isButtonDisabled}
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SiteManager;
