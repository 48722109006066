import React from "react";
import "./contact.css";
import JobApplication from "./jobApplication/JobApplication";
import FollowUs from "./followUs/FollowUs";
import ContactUs from "./contactUs/ContactUs";

const Connect = () => {
  return (
    <div className="connect_follow">
      <JobApplication />

      <hr />

      <FollowUs />

      <hr />

      <ContactUs />
    </div>
  );
};

export default Connect;
