import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaCarSide } from "react-icons/fa";

const Delivery = ({ direction }) => {
  const [dataDelivery, setDataDelivery] = useState([]);
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;

  const fetchDataDelivery = async () => {
    try {
      const response = await fetch(`${LinkLikeBachend}read/view_delivery.php`);
      const dataDeliveryAraay = await response.json();
      setDataDelivery(dataDeliveryAraay);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  useEffect(() => {
    fetchDataDelivery();
  }, []);

  const { t } = useTranslation();

  return (
    <div className="delivery">
      {dataDelivery.length > 0 ? (
        <>
          <h2>{t("delivery")}</h2>

          {direction === "rtl" ? (
            <>
              {dataDelivery.map((item, index) => (
                <a href={item.link} target="_blank" key={index}>
                  <FaCarSide />

                  <span>{item.ar_delivery}</span>
                </a>
              ))}
            </>
          ) : (
            <>
              {dataDelivery.map((item, index) => (
                <a href={item.link} target="_blank" key={index}>
                  <FaCarSide />

                  <span>{item.en_delivery}</span>
                </a>
              ))}
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Delivery;
