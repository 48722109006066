import React from "react";
import "./footer.css";
import { useTranslation } from "react-i18next";

const Footer = ({ setDirection }) => {
  const { i18n } = useTranslation();

  const ChangeEN = () => {
    i18n.changeLanguage("en");
    setDirection("ltr");
  };

  const ChangeAR = () => {
    i18n.changeLanguage("ar");
    setDirection("rtl");
  };
  return (
    <footer>
      <button onClick={ChangeAR}>AR</button>
      <button onClick={ChangeEN}>EN</button>
    </footer>
  );
};

export default Footer;
