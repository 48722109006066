export const englishDataEn = {
  NotFound_p:
    "The page you are looking for might have been removed, its URL changed or is temporarily unavailable.",
  NotFound_a: "Home Page",
  menu: "Menu",
  delivery: "Delivery",
  Branch_locations: "Branch locations",
  job_application: "Job Application",
  follow: "Follow us",
};

export default englishDataEn;
