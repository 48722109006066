import { Navigate } from "react-router-dom";
import Header from "../dashboard/header/Header";

const Authenticated = ({ children }) => {
  const authSuccess = localStorage.getItem("loginSuccess");

  if (authSuccess !== "true") {
    return <Navigate to="/admin" />;
  }
  return (
    <>
      <Header />
      {children}
    </>
  );
};

export default Authenticated;
