// App.js
import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import NotFound from "./pages/notFound/NotFound";
import Home from "./pages/home/Home";
import SiteManager from "./admin/SiteManager";
import Dashboard from "./admin/dashboard/Dashboard";
import Delivery from "./admin/dashboard/delivery/Delivery";
import Branches from "./admin/dashboard/branches/Branches";
import Contact from "./admin/dashboard/contact/Contact";
import ShowFooter from "./components/showFooter/ShowFooter";
import Authenticated from "./admin/authenticated/Authenticated";
import LogoSlide from "./admin/dashboard/logo_slide/LogoSlide";

const App = () => {
  const [direction, setDirection] = useState("rtl");

  return (
    <div style={{ direction: direction }}>
      <Routes>
        <Route
          path="/"
          element={
            <ShowFooter setDirection={setDirection}>
              <Home direction={direction} />
            </ShowFooter>
          }
        />

        <Route path=":admin" element={<SiteManager />} />

        <Route
          path="dashboard"
          element={
            <Authenticated>
              <Dashboard />
            </Authenticated>
          }
        >
          <Route index element={<LogoSlide />} />

          <Route path="logo-slide" element={<LogoSlide />} />

          <Route path="delivery" element={<Delivery />} />

          <Route path="branches" element={<Branches />} />

          <Route path="connect" element={<Contact />} />
        </Route>

        <Route
          path="*"
          element={
            <ShowFooter>
              <NotFound />
            </ShowFooter>
          }
        />
      </Routes>
    </div>
  );
};

export default App;
