import React, { useState } from "react";
import Logo from "./content/logo/Logo";
import { useTranslation } from "react-i18next";
import { FaRegRectangleList } from "react-icons/fa6";
import Manu from "./content/menu/Manu";
import Delivery from "./content/delivery/Delivery";
import Branches from "./content/branches/Branches";
import JobApplication from "./content/jobApplication/JobApplication";
import Follow from "./content/follow/Follow";

const Home = ({ direction }) => {
  const { t } = useTranslation();
  const [isShowSlider, setIsShowSlider] = useState(false);

  const handlesetShowSlider = () => {
    setIsShowSlider(true);
  };

  return (
    <div className="home_content">
      <Logo />

      <div className="menu">
        <button onClick={handlesetShowSlider}>
          {t("menu")} <FaRegRectangleList />
        </button>
        {isShowSlider && <Manu setIsShowSlider={setIsShowSlider} />}
      </div>

      <div className="home_contents">
        <div className="All_home_contents">
          <Delivery direction={direction} />
        </div>

        <div className="All_home_contents">
          <Branches direction={direction} />
        </div>

        <div className="All_home_contents">
          <JobApplication direction={direction} />
        </div>

        <div className="All_home_contents">
          <Follow direction={direction} />
        </div>
      </div>
    </div>
  );
};

export default Home;
