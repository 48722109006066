import React, { useEffect, useState } from "react";

const Logo = () => {
  const [imageSrc, setImageSrc] = useState("");
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    fetch(`${LinkLikeBachend}read/view_logo.php`)
      .then((response) => response.text())
      .then((imageUrl) => {
        setImageSrc(imageUrl);
      })
      .catch((error) => console.error("Error fetching image:", error));
  }, []);

  return (
    <div className="logo_home">
      <img src={`${LinkLikeBachend}${imageSrc}`} alt="logo" />
    </div>
  );
};

export default Logo;
