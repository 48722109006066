import React, { useEffect, useState } from "react";
import "../../siteManager.css";
import { toast } from "react-toastify";
import Menu from "../menu/Menu";

const LogoSlide = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;

  const view_logo = () => {
    fetch(`${LinkLikeBachend}read/view_logo.php`)
      .then((response) => response.text())
      .then((imageUrl) => {
        setImageSrc(imageUrl);
      })
      .catch((error) => console.error("Error fetching image:", error));
  };

  useEffect(() => {
    view_logo();
  }, []);

  const handleImageChange = (e) => {
    const image = e.target.files[0];
    setSelectedImage(image);

    if (image) {
      const imageUrl = URL.createObjectURL(image);
      setImagePreview(imageUrl);
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();

    setButtonDisabled(true);

    const formData = new FormData();
    formData.append("selectedImage", selectedImage);

    const response = await fetch(`${LinkLikeBachend}update/upload_logo.php`, {
      method: "POST",
      body: formData,
    });

    const result = await response.json();

    if (response.ok) {
      if (result.message == "successfully") {
        toast.success("The image has been uploaded successfully");

        setSelectedImage(null);
        setImagePreview(null);

        setButtonDisabled(false);

        view_logo();
      } else {
        toast.error("An error occurred while adding the image");
        console.log(result.message);
        console.log(result.error);

        setButtonDisabled(false);
      }
    } else {
      const errorText = await response.text();
      console.log("حدث خطأ:", errorText);
      setButtonDisabled(false);
    }
  };

  return (
    <>
      <form method="POST" onSubmit={handleUpload} className="formUploadLogo">
        <h2>Logo</h2>

        <div className="fileUploadLogo">
          <input
            type="file"
            onChange={handleImageChange}
            className="inputFile"
            accept="image/png"
            required
          />
          <img
            src={imagePreview ? imagePreview : `${LinkLikeBachend}${imageSrc}`}
            alt="معاينة الصورة"
            id="logoInput"
            className="previewImage"
          />
        </div>

        <button type="submit" disabled={isButtonDisabled}>
          تحميل الصورة
        </button>
      </form>

      <hr />

      <Menu />
    </>
  );
};

export default LogoSlide;
