import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaRegRectangleList } from "react-icons/fa6";

const JobApplication = ({ direction }) => {
  const [dataJobApplication, setDataJobApplication] = useState([]);
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;

  const fetchDataJobApplication = async () => {
    try {
      const response = await fetch(
        `${LinkLikeBachend}read/view_JobApplication.php`
      );
      const dataJobApplicationAraay = await response.json();
      setDataJobApplication(dataJobApplicationAraay);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  useEffect(() => {
    fetchDataJobApplication();
  }, []);

  const { t } = useTranslation();

  return (
    <div className="jobApplication">
      {dataJobApplication.length > 0 ? (
        <>
          <h2>{t("job_application")}</h2>
          {direction === "rtl" ? (
            <>
              {dataJobApplication.map((item, index) => (
                <a href={item.link} target="_blank" key={index}>
                  <FaRegRectangleList />

                  <span>{item.ar_job_application}</span>
                </a>
              ))}
            </>
          ) : (
            <>
              {dataJobApplication.map((item, index) => (
                <a href={item.link} target="_blank" key={index}>
                  <FaRegRectangleList />

                  <span>{item.en_job_application}</span>
                </a>
              ))}
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default JobApplication;
