export const arabicDataAr = {
  NotFound_p:
    "ربما تمت إزالة الصفحة التي تبحث عنها، أو تم تغيير عنوان URL الخاص بها، أو أنها غير متاحة مؤقتًا.",
  NotFound_a: "الصفحة الرئيسية",
  menu: "المنيو",
  delivery: "توصيل",
  Branch_locations: "مواقع الفروع",
  job_application: "طلب توظيف",
  follow: "تابعنا",
};

export default arabicDataAr;
