import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FaInstagram,
  FaWhatsapp,
  FaTwitter,
  FaYoutube,
  FaTiktok,
  FaFacebook,
} from "react-icons/fa";
import { IoCall } from "react-icons/io5";

const Follow = ({ direction }) => {
  const [dataFollow, setDataFollow] = useState([]);
  const [dataContactUs, setDataContactUs] = useState([]);
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;

  const fetchDataFollow = async () => {
    try {
      const response = await fetch(`${LinkLikeBachend}read/view_FollowUs.php`);
      const dataFollowAraay = await response.json();
      setDataFollow(dataFollowAraay);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const fetchDataContactUs = async () => {
    try {
      const response = await fetch(`${LinkLikeBachend}read/view_ContactUs.php`);
      const dataContactUs = await response.json();
      setDataContactUs(dataContactUs);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  useEffect(() => {
    fetchDataFollow();
    fetchDataContactUs();
  }, []);

  const { t } = useTranslation();

  return (
    <>
      {dataFollow.length > 0 || dataContactUs.length > 0 ? (
        <>
          <h2>{t("follow")}</h2>

          <div className="follow">
            {dataFollow.length > 0 ? (
              <>
                {dataFollow.map((item, index) => {
                  let alternativeText;

                  switch (item.name_view_follow) {
                    case "twitter":
                      alternativeText = <FaTwitter />;
                      break;
                    case "youtube":
                      alternativeText = <FaYoutube />;
                      break;
                    case "tiktok":
                      alternativeText = <FaTiktok />;
                      break;
                    case "whatsapp":
                      alternativeText = <FaWhatsapp />;
                      break;
                    case "instagram":
                      alternativeText = <FaInstagram />;
                      break;
                    case "facebook":
                      alternativeText = <FaFacebook />;
                      break;
                    default:
                      alternativeText = "";
                      break;
                  }

                  return (
                    <a href={item.link} target="_blank" key={index}>
                      <span>{alternativeText}</span>
                    </a>
                  );
                })}
              </>
            ) : (
              <></>
            )}
          </div>

          <div className="ContactUs">
            {dataContactUs.length > 0 ? (
              direction === "rtl" ? (
                <>
                  {dataContactUs.map((item, index) => (
                    <a href={`tel:${item.link}`} target="_blank" key={index}>
                      <IoCall />

                      <span>{item.ar_contact_us}</span>
                    </a>
                  ))}
                </>
              ) : (
                <>
                  {dataContactUs.map((item, index) => (
                    <a href={`tel:${item.link}`} target="_blank" key={index}>
                      <IoCall />

                      <span>{item.en_contact_us}</span>
                    </a>
                  ))}
                </>
              )
            ) : (
              <></>
            )}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Follow;
