import React, { useEffect, useState } from "react";
import "./delivery.css";
import { toast } from "react-toastify";

const Delivery = () => {
  const [link, setLink] = useState("");
  const [langEN, setLangEN] = useState("");
  const [langAR, setLangAR] = useState("");
  const [isDisabledAddLink, setIsDisabledAddLink] = useState(false);
  const [dataDelivery, setDataDelivery] = useState([]);
  const [formDataDelivery, setFormDataDelivery] = useState([]);
  const [isDisabledUpdate, setIsDisabledUpdate] = useState(false);
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;

  const fetchDataDelivery = async () => {
    try {
      const response = await fetch(`${LinkLikeBachend}read/view_delivery.php`);
      const dataDeliveryAraay = await response.json();
      setDataDelivery(dataDeliveryAraay);

      const initialFormData = dataDeliveryAraay.map((item) => ({ ...item }));
      setFormDataDelivery(initialFormData);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  useEffect(() => {
    fetchDataDelivery();
  }, []);

  const handleSubmitDelivery = async (e) => {
    e.preventDefault();

    setIsDisabledAddLink(true);

    const formDataDelivery = new FormData();
    formDataDelivery.append("link", link);
    formDataDelivery.append("langEN", langEN);
    formDataDelivery.append("langAR", langAR);

    const response = await fetch(`${LinkLikeBachend}create/add_delivery.php`, {
      method: "POST",
      body: formDataDelivery,
    });

    const resultText = await response.text();

    if (response.ok) {
      if (resultText == "successfully") {
        toast.success("The statement has been added successfully");

        setLink("");

        setLangEN("");

        setLangAR("");

        setIsDisabledAddLink(false);

        fetchDataDelivery();
      } else {
        toast.error("Error when adding statement");
        setIsDisabledAddLink(false);
      }
    } else {
      const errorText = await response.text();
      console.log("حدث خطأ:", errorText);
      setIsDisabledAddLink(false);
    }
  };

  const handleRemovedataDelivery = async (idDelivery) => {
    const formDataRemoveDelivery = new FormData();
    formDataRemoveDelivery.append("idDelivery", idDelivery);

    const response = await fetch(
      `${LinkLikeBachend}delete/delete_delivery.php`,
      {
        method: "POST",
        body: formDataRemoveDelivery,
      }
    );

    const result = await response.text();

    if (response.ok) {
      if (result == "successfully") {
        toast.success("The data has been deleted successfully");

        setDataDelivery(
          dataDelivery.filter((itemdata) => itemdata.id !== idDelivery)
        );
      } else {
        toast.error("Error when deleting data");
        console.log(result);
      }
    } else {
      const errorText = await response.text();
      console.log("حدث خطأ:", errorText);
    }
  };

  const handleSubmitDeliveryUpdate = async (e, index) => {
    e.preventDefault();
    setIsDisabledUpdate(true);

    const updatedData = formDataDelivery[index];

    const response = await fetch(
      `${LinkLikeBachend}update/update_delivery.php`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedData),
      }
    );

    const result = await response.text();

    if (response.ok) {
      if (result === "successfully") {
        toast.success("The statement has been updated successfully");
        setIsDisabledUpdate(false);

        fetchDataDelivery();
      } else {
        toast.error("Error when updating statement");
        setIsDisabledUpdate(false);
        console.log(result);
      }
    } else {
      console.log("حدث خطأ:", result);
      setIsDisabledUpdate(false);
    }
  };

  const handleInputChange = (index, field, value) => {
    const updatedFormData = [...formDataDelivery];
    updatedFormData[index][field] = value;
    setFormDataDelivery(updatedFormData);
  };

  return (
    <>
      <form onSubmit={handleSubmitDelivery} className="deliveryForm">
        <input
          type="url"
          value={link}
          onChange={(e) => setLink(e.target.value)}
          placeholder="Delivery application link"
          required
        />

        <input
          type="text"
          value={langEN}
          onChange={(e) => setLangEN(e.target.value)}
          placeholder="Lang EN"
          required
        />

        <input
          type="text"
          value={langAR}
          onChange={(e) => setLangAR(e.target.value)}
          placeholder="Lang AR"
          required
        />

        <button type="submit" disabled={isDisabledAddLink}>
          Submit
        </button>
      </form>

      {dataDelivery.length > 0 ? (
        <div className="deliveryFormDiv">
          {dataDelivery.map((itemdata, index) => (
            <div key={index}>
              <button
                className="removedataDelivery"
                onClick={() => handleRemovedataDelivery(itemdata.id)}
              >
                ×
              </button>

              <form
                onSubmit={(e) => handleSubmitDeliveryUpdate(e, index)}
                className="deliveryFormUpdate"
              >
                <input
                  type="url"
                  value={formDataDelivery[index].link}
                  onChange={(e) =>
                    handleInputChange(index, "link", e.target.value)
                  }
                  placeholder="Delivery application link"
                  required
                />

                <input
                  type="text"
                  value={formDataDelivery[index].en_delivery}
                  onChange={(e) =>
                    handleInputChange(index, "en_delivery", e.target.value)
                  }
                  placeholder="Lang EN"
                  required
                />

                <input
                  type="text"
                  value={formDataDelivery[index].ar_delivery}
                  onChange={(e) =>
                    handleInputChange(index, "ar_delivery", e.target.value)
                  }
                  placeholder="Lang AR"
                  required
                />

                <button type="submit" disabled={isDisabledUpdate}>
                  Update
                </button>
              </form>
            </div>
          ))}
        </div>
      ) : (
        <p style={{ textAlign: "center" }}>No images available.</p>
      )}
    </>
  );
};

export default Delivery;
