import React, { useEffect, useState } from "react";
import "../../siteManager.css";
import { toast } from "react-toastify";

const Menu = () => {
  const [selectedImageMenu, setSelectedImageMenu] = useState(null);
  const [imagePreviewMenu, setImagePreviewMenu] = useState(null);
  const [isButtonDisabledMenu, setButtonDisabledMenu] = useState(false);
  const [imagesMenus, setImagesMenus] = useState([]);
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;

  const fetchImages = async () => {
    try {
      const response = await fetch(`${LinkLikeBachend}read/view_menu.php`);
      const dataMenus = await response.json();
      setImagesMenus(dataMenus);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  useEffect(() => {
    fetchImages();
  }, []);

  const handleImageChangeMenu = (e) => {
    const imageMenu = e.target.files[0];
    setSelectedImageMenu(imageMenu);

    if (imageMenu) {
      const imageUrlMenu = URL.createObjectURL(imageMenu);
      setImagePreviewMenu(imageUrlMenu);
    }
  };

  const handleUploadMenu = async (e) => {
    e.preventDefault();

    setButtonDisabledMenu(true);

    const formDataMenu = new FormData();
    formDataMenu.append("selectedImage", selectedImageMenu);

    const response = await fetch(`${LinkLikeBachend}create/add_menu.php`, {
      method: "POST",
      body: formDataMenu,
    });

    const result = await response.json();

    if (response.ok) {
      if (result.message == "successfully") {
        toast.success("The image has been uploaded successfully");

        setSelectedImageMenu("");
        setImagePreviewMenu("");

        setButtonDisabledMenu(false);

        fetchImages();
      } else {
        toast.error("An error occurred while adding the image");
        console.log(result.message);
        console.log(result.error);

        setButtonDisabledMenu(false);
      }
    } else {
      const errorText = await response.text();
      console.log("حدث خطأ:", errorText);
      setButtonDisabledMenu(false);
    }
  };

  const handleRemoveImage = async (idImgSlider) => {
    const formDataRemoveImage = new FormData();
    formDataRemoveImage.append("idImgSlider", idImgSlider);

    const response = await fetch(
      `${LinkLikeBachend}delete/delete_image_menu.php`,
      {
        method: "POST",
        body: formDataRemoveImage,
      }
    );

    const result = await response.text();

    if (response.ok) {
      if (result == "successfully") {
        toast.success("The image has been uploaded successfully");

        setImagesMenus(imagesMenus.filter((image) => image.id !== idImgSlider));
      } else {
        toast.error("An error occurred while adding the image");
        console.log(result);
      }
    } else {
      const errorText = await response.text();
      console.log("حدث خطأ:", errorText);
    }
  };

  return (
    <>
      <form
        method="POST"
        onSubmit={handleUploadMenu}
        className="formUploadLogo"
      >
        <h2>Menu</h2>

        <div className="fileUploadLogo">
          <input
            type="file"
            onChange={handleImageChangeMenu}
            className="inputFile"
            accept="image/*"
            required
          />
          <img
            src={imagePreviewMenu ? imagePreviewMenu : "./images/image.webp"}
            alt="معاينة الصورة"
            id="logoInput"
            className="previewImage"
          />
        </div>

        <button type="submit" disabled={isButtonDisabledMenu}>
          تحميل المنيو
        </button>
      </form>

      {imagesMenus.length > 0 ? (
        <div className="imagesMenus">
          {imagesMenus.map((image, index) => (
            <div key={index} className="imageMenu">
              <button onClick={() => handleRemoveImage(image.id)}>×</button>
              <img
                src={`${LinkLikeBachend}${image.image}`}
                alt={`Image_${index}`}
              />
            </div>
          ))}
        </div>
      ) : (
        <p style={{ textAlign: "center" }}>No images available.</p>
      )}
    </>
  );
};

export default Menu;
