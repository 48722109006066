import React from "react";
import "./header.css";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogOutAdmin = () => {
    localStorage.clear();

    navigate("/admin", { replace: true });
  };

  return (
    <>
      <div className="topnavHeader">
        <Link
          className={location.pathname === "/dashboard" ? "active" : ""}
          to="/dashboard"
        >
          Home
        </Link>
        <Link
          className={
            location.pathname === "/dashboard/delivery" ? "active" : ""
          }
          to="delivery"
        >
          Delivery
        </Link>
        <Link
          className={
            location.pathname === "/dashboard/branches" ? "active" : ""
          }
          to="branches"
        >
          Branches
        </Link>
        <Link
          className={location.pathname === "/dashboard/connect" ? "active" : ""}
          to="connect"
        >
          Connect
        </Link>

        <button onClick={handleLogOutAdmin}>LogOut</button>
      </div>

      <Outlet />
    </>
  );
};

export default Header;
