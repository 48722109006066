import React from "react";
import { NavLink } from "react-router-dom";
import "./notFound.css";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <div className="main_notFound">
      <div className="content">
        <h1>
          4<span>0</span>4
        </h1>
        <p>{t("NotFound_p")}</p>
        <NavLink to="/">{t("NotFound_a")}</NavLink>
      </div>
    </div>
  );
};

export default NotFound;
