import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "../contact.css";

const JobApplication = () => {
  const [linkJobApplication, setLinkJobApplication] = useState("");
  const [langENJobApplication, setLangENJobApplication] = useState("");
  const [langARJobApplication, setLangARJobApplication] = useState("");
  const [isDisabledAddLinkJobApplication, setIsDisabledAddLinkJobApplication] =
    useState(false);
  const [dataJobApplication, setDataJobApplication] = useState([]);
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;

  const fetchDataJobApplication = async () => {
    try {
      const response = await fetch(
        `${LinkLikeBachend}read/view_JobApplication.php`
      );
      const dataJobApplicationAraay = await response.json();
      setDataJobApplication(dataJobApplicationAraay);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  useEffect(() => {
    fetchDataJobApplication();
  }, []);

  const handleSubmitJobApplication = async (e) => {
    e.preventDefault();

    setIsDisabledAddLinkJobApplication(true);

    const formDataJobApplication = new FormData();
    formDataJobApplication.append("link", linkJobApplication);
    formDataJobApplication.append("langEN", langENJobApplication);
    formDataJobApplication.append("langAR", langARJobApplication);

    const response = await fetch(
      `${LinkLikeBachend}create/add_JobApplication.php`,
      {
        method: "POST",
        body: formDataJobApplication,
      }
    );

    const resultText = await response.text();

    if (response.ok) {
      if (resultText == "successfully") {
        toast.success("The statement has been added successfully");

        setLinkJobApplication("");

        setLangENJobApplication("");

        setLangARJobApplication("");

        setIsDisabledAddLinkJobApplication(false);

        fetchDataJobApplication();
      } else {
        toast.error("Error when adding statement");
        setIsDisabledAddLinkJobApplication(false);
      }
    } else {
      const errorText = await response.text();
      console.log("حدث خطأ:", errorText);
      setIsDisabledAddLinkJobApplication(false);
    }
  };

  const handleRemovedataJobApplication = async (idJobApplication) => {
    const formDataRemoveJobApplication = new FormData();
    formDataRemoveJobApplication.append("idJobApplication", idJobApplication);

    const response = await fetch(
      `${LinkLikeBachend}delete/delete_JobApplication.php`,
      {
        method: "POST",
        body: formDataRemoveJobApplication,
      }
    );

    const result = await response.text();

    if (response.ok) {
      if (result == "successfully") {
        toast.success("The data has been deleted successfully");

        setDataJobApplication(
          dataJobApplication.filter(
            (itemdata) => itemdata.id !== idJobApplication
          )
        );
      } else {
        toast.error("Error when deleting data");
        console.log(result);
      }
    } else {
      const errorText = await response.text();
      console.log("حدث خطأ:", errorText);
    }
  };

  return (
    <div>
      <form
        onSubmit={handleSubmitJobApplication}
        className="JobApplicationForm"
      >
        <input
          type="url"
          value={linkJobApplication}
          onChange={(e) => setLinkJobApplication(e.target.value)}
          placeholder="Job application link"
          required
        />

        <input
          type="text"
          value={langENJobApplication}
          onChange={(e) => setLangENJobApplication(e.target.value)}
          placeholder="Lang EN"
          required
        />

        <input
          type="text"
          value={langARJobApplication}
          onChange={(e) => setLangARJobApplication(e.target.value)}
          placeholder="Lang AR"
          required
        />

        <button type="submit" disabled={isDisabledAddLinkJobApplication}>
          Submit
        </button>
      </form>

      {dataJobApplication.length > 0 ? (
        <div className="JobApplicationFormDiv">
          {dataJobApplication.map((itemdata, index) => (
            <div key={index}>
              <button
                className="removedataJobApplication"
                onClick={() => handleRemovedataJobApplication(itemdata.id)}
              >
                ×
              </button>

              <div className="JobApplicationFormUpdate">
                <a href={itemdata.link} target="_blank">
                  {itemdata.link}
                </a>
                <p>{itemdata.en_job_application}</p>
                <p>{itemdata.ar_job_application}</p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p style={{ textAlign: "center" }}>No images available.</p>
      )}
    </div>
  );
};

export default JobApplication;
