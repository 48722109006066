import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "../contact.css";

const ContactUs = () => {
  const [linkContactUs, setLinkContactUs] = useState("");
  const [langENContactUs, setLangENContactUs] = useState("");
  const [langARContactUs, setLangARContactUs] = useState("");
  const [isDisabledAddLinkContactUs, setIsDisabledAddLinkContactUs] =
    useState(false);
  const [dataContactUs, setDataContactUs] = useState([]);
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;

  const fetchDataContactUs = async () => {
    try {
      const response = await fetch(`${LinkLikeBachend}read/view_ContactUs.php`);
      const dataContactUsAraay = await response.json();
      setDataContactUs(dataContactUsAraay);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  useEffect(() => {
    fetchDataContactUs();
  }, []);

  const handleSubmitContactUs = async (e) => {
    e.preventDefault();

    setIsDisabledAddLinkContactUs(true);

    const formDataContactUs = new FormData();
    formDataContactUs.append("link", linkContactUs);
    formDataContactUs.append("langEN", langENContactUs);
    formDataContactUs.append("langAR", langARContactUs);

    const response = await fetch(`${LinkLikeBachend}create/add_ContactUs.php`, {
      method: "POST",
      body: formDataContactUs,
    });

    const resultText = await response.text();

    if (response.ok) {
      if (resultText == "successfully") {
        toast.success("The statement has been added successfully");

        setLinkContactUs("");

        setLangENContactUs("");

        setLangARContactUs("");

        setIsDisabledAddLinkContactUs(false);

        fetchDataContactUs();
      } else {
        toast.error("Error when adding statement");
        setIsDisabledAddLinkContactUs(false);
      }
    } else {
      const errorText = await response.text();
      console.log("حدث خطأ:", errorText);
      setIsDisabledAddLinkContactUs(false);
    }
  };

  const handleRemovedataContactUs = async (idContactUs) => {
    const formDataRemoveContactUs = new FormData();
    formDataRemoveContactUs.append("idContactUs", idContactUs);

    const response = await fetch(
      `${LinkLikeBachend}delete/delete_ContactUs.php`,
      {
        method: "POST",
        body: formDataRemoveContactUs,
      }
    );

    const result = await response.text();

    if (response.ok) {
      if (result == "successfully") {
        toast.success("The data has been deleted successfully");

        setDataContactUs(
          dataContactUs.filter((itemdata) => itemdata.id !== idContactUs)
        );
      } else {
        toast.error("Error when deleting data");
        console.log(result);
      }
    } else {
      const errorText = await response.text();
      console.log("حدث خطأ:", errorText);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmitContactUs} className="ContactUsForm">
        <input
          type="number"
          value={linkContactUs}
          onChange={(e) => setLinkContactUs(e.target.value)}
          placeholder="Communication link"
          required
        />

        <input
          type="text"
          value={langENContactUs}
          onChange={(e) => setLangENContactUs(e.target.value)}
          placeholder="Lang EN"
          required
        />

        <input
          type="text"
          value={langARContactUs}
          onChange={(e) => setLangARContactUs(e.target.value)}
          placeholder="Lang AR"
          required
        />

        <button type="submit" disabled={isDisabledAddLinkContactUs}>
          Submit
        </button>
      </form>

      {dataContactUs.length > 0 ? (
        <div className="ContactUsFormDiv">
          {dataContactUs.map((itemdata, index) => (
            <div key={index}>
              <button
                className="removedataContactUs"
                onClick={() => handleRemovedataContactUs(itemdata.id)}
              >
                ×
              </button>

              <div className="ContactUsFormUpdate">
                <a href={`tel:${itemdata.link}`} target="_blank">
                  {itemdata.link}
                </a>
                <p>{itemdata.en_contact_us}</p>
                <p>{itemdata.ar_contact_us}</p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p style={{ textAlign: "center" }}>No images available.</p>
      )}
    </div>
  );
};

export default ContactUs;
