import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "./branches.css";

const Branches = () => {
  const [linkBranches, setLinkBranches] = useState("");
  const [langENBranches, setLangENBranches] = useState("");
  const [langARBranches, setLangARBranches] = useState("");
  const [isDisabledAddLinkBranches, setIsDisabledAddLinkBranches] =
    useState(false);
  const [dataBranches, setDataBranches] = useState([]);
  const [formDataBranches, setFormDataBranches] = useState([]);
  const [isDisabledUpdateBranches, setIsDisabledUpdateBranches] =
    useState(false);
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;

  const fetchDataBranches = async () => {
    try {
      const response = await fetch(`${LinkLikeBachend}read/view_branches.php`);
      const dataBranchesAraay = await response.json();
      setDataBranches(dataBranchesAraay);

      const initialFormData = dataBranchesAraay.map((item) => ({ ...item }));
      setFormDataBranches(initialFormData);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  useEffect(() => {
    fetchDataBranches();
  }, []);

  const handleSubmitBranches = async (e) => {
    e.preventDefault();

    setIsDisabledAddLinkBranches(true);

    const formDataBranches = new FormData();
    formDataBranches.append("link", linkBranches);
    formDataBranches.append("langEN", langENBranches);
    formDataBranches.append("langAR", langARBranches);

    const response = await fetch(`${LinkLikeBachend}create/add_branches.php`, {
      method: "POST",
      body: formDataBranches,
    });

    const resultText = await response.text();

    if (response.ok) {
      if (resultText == "successfully") {
        toast.success("The statement has been added successfully");

        setLinkBranches("");

        setLangENBranches("");

        setLangARBranches("");

        setIsDisabledAddLinkBranches(false);

        fetchDataBranches();
      } else {
        toast.error("Error when adding statement");
        setIsDisabledAddLinkBranches(false);
      }
    } else {
      const errorText = await response.text();
      console.log("حدث خطأ:", errorText);
      setIsDisabledAddLinkBranches(false);
    }
  };

  const handleRemovedataBranches = async (idBranches) => {
    const formDataRemoveBranches = new FormData();
    formDataRemoveBranches.append("idBranches", idBranches);

    const response = await fetch(
      `${LinkLikeBachend}delete/delete_branches.php`,
      {
        method: "POST",
        body: formDataRemoveBranches,
      }
    );

    const result = await response.text();

    if (response.ok) {
      if (result == "successfully") {
        toast.success("The data has been deleted successfully");

        setDataBranches(
          dataBranches.filter((itemdata) => itemdata.id !== idBranches)
        );
      } else {
        toast.error("Error when deleting data");
        console.log(result);
      }
    } else {
      const errorText = await response.text();
      console.log("حدث خطأ:", errorText);
    }
  };

  const handleSubmitBranchesUpdate = async (e, index) => {
    e.preventDefault();
    setIsDisabledUpdateBranches(true);

    const updatedData = formDataBranches[index];

    const response = await fetch(
      `${LinkLikeBachend}update/update_branches.php`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedData),
      }
    );

    const result = await response.text();

    if (response.ok) {
      if (result === "successfully") {
        toast.success("The statement has been updated successfully");
        setIsDisabledUpdateBranches(false);

        fetchDataBranches();
      } else {
        toast.error("Error when updating statement");
        setIsDisabledUpdateBranches(false);
        console.log(result);
      }
    } else {
      console.log("حدث خطأ:", result);
      setIsDisabledUpdateBranches(false);
    }
  };

  const handleInputChangeBranches = (index, field, value) => {
    const updatedFormData = [...formDataBranches];
    updatedFormData[index][field] = value;
    setFormDataBranches(updatedFormData);
  };

  return (
    <>
      <form onSubmit={handleSubmitBranches} className="BranchesForm">
        <input
          type="url"
          value={linkBranches}
          onChange={(e) => setLinkBranches(e.target.value)}
          placeholder="link google map"
          required
        />

        <input
          type="text"
          value={langENBranches}
          onChange={(e) => setLangENBranches(e.target.value)}
          placeholder="Lang EN"
          required
        />

        <input
          type="text"
          value={langARBranches}
          onChange={(e) => setLangARBranches(e.target.value)}
          placeholder="Lang AR"
          required
        />

        <button type="submit" disabled={isDisabledAddLinkBranches}>
          Submit
        </button>
      </form>

      {dataBranches.length > 0 ? (
        <div className="BranchesFormDiv">
          {dataBranches.map((itemdata, index) => (
            <div key={index}>
              <button
                className="removedataBranches"
                onClick={() => handleRemovedataBranches(itemdata.id)}
              >
                ×
              </button>

              <form
                onSubmit={(e) => handleSubmitBranchesUpdate(e, index)}
                className="BranchesFormUpdate"
              >
                <input
                  type="url"
                  value={formDataBranches[index].link}
                  onChange={(e) =>
                    handleInputChangeBranches(index, "link", e.target.value)
                  }
                  placeholder="link google map"
                  required
                />

                <input
                  type="text"
                  value={formDataBranches[index].en_branches}
                  onChange={(e) =>
                    handleInputChangeBranches(
                      index,
                      "en_branches",
                      e.target.value
                    )
                  }
                  placeholder="Lang EN"
                  required
                />

                <input
                  type="text"
                  value={formDataBranches[index].ar_branches}
                  onChange={(e) =>
                    handleInputChangeBranches(
                      index,
                      "ar_branches",
                      e.target.value
                    )
                  }
                  placeholder="Lang AR"
                  required
                />

                <button type="submit" disabled={isDisabledUpdateBranches}>
                  Update
                </button>
              </form>
            </div>
          ))}
        </div>
      ) : (
        <p style={{ textAlign: "center" }}>No images available.</p>
      )}
    </>
  );
};

export default Branches;
