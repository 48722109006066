import React, { useState, useEffect } from "react";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import { IoCloseCircleOutline } from "react-icons/io5";

const Manu = ({ setIsShowSlider }) => {
  const [slideIndex, setSlideIndex] = useState(1);
  const [imagesMenus, setImagesMenus] = useState([]);
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;

  const fetchImages = async () => {
    try {
      const response = await fetch(`${LinkLikeBachend}read/view_menu.php`);
      const dataMenus = await response.json();
      setImagesMenus(dataMenus);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  useEffect(() => {
    showSlides(slideIndex);
    fetchImages();
  }, [slideIndex, imagesMenus]);

  const plusSlides = (n) => {
    setSlideIndex((prevIndex) => prevIndex + n);
  };

  const showSlides = (n) => {
    let i;
    let slides = document.getElementsByClassName("mySlides");
    if (slides.length === 0) return;

    if (n > slides.length) setSlideIndex(1);
    if (n < 1) setSlideIndex(slides.length);

    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }

    if (slides[slideIndex - 1]) {
      slides[slideIndex - 1].style.display = "flex";
    }
  };

  const handleCloseMenu = () => {
    setIsShowSlider(false);
  };

  return (
    <div className="slideshow_menu">
      <IoCloseCircleOutline className="close_menu" onClick={handleCloseMenu} />

      <div className="slideshow-container">
        {imagesMenus.length > 0 ? (
          <>
            {imagesMenus.map((image, index) => (
              <div key={index} className="mySlides fade">
                <img
                  src={`${LinkLikeBachend}${image.image}`}
                  alt={`Image_${index}`}
                />
              </div>
            ))}
          </>
        ) : (
          <p style={{ textAlign: "center" }}>No images available.</p>
        )}
      </div>

      {imagesMenus.length > 1 ? (
        <div className="prev_next">
          <button className="prev" onClick={() => plusSlides(-1)}>
            <FaChevronRight />
          </button>
          <button className="next" onClick={() => plusSlides(1)}>
            <FaChevronLeft />
          </button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Manu;
