import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LuMapPin } from "react-icons/lu";

const Branches = ({ direction }) => {
  const [dataBranches, setDataBranches] = useState([]);
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;

  const fetchDataBranches = async () => {
    try {
      const response = await fetch(`${LinkLikeBachend}read/view_branches.php`);
      const dataBranchesAraay = await response.json();
      setDataBranches(dataBranchesAraay);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  useEffect(() => {
    fetchDataBranches();
  }, []);

  const { t } = useTranslation();

  return (
    <div className="branches">
      {dataBranches.length > 0 ? (
        <>
          <h2>{t("Branch_locations")}</h2>
          {direction === "rtl" ? (
            <>
              {dataBranches.map((item, index) => (
                <a href={item.link} target="_blank" key={index}>
                  <LuMapPin />

                  <span>{item.ar_branches}</span>
                </a>
              ))}
            </>
          ) : (
            <>
              {dataBranches.map((item, index) => (
                <a href={item.link} target="_blank" key={index}>
                  <LuMapPin />

                  <span>{item.en_branches}</span>
                </a>
              ))}
            </>
          )}{" "}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Branches;
